import React from "react"
import { PageProps, Link } from "gatsby"
import PathContext from "../contexts/path.context"
import Layout from "../layouts/Primary/layout"
import HeroMazeDefault from "../components/hero-default"
import { isInBrowser } from '../utils/helpers';

interface INotFoundPageContext {
  id: string
  slug: string
  locale: string
}

const NotFoundPage: React.FC<PageProps<INotFoundPageContext>> = ({
  pageContext,
  path,
}) => {
  const locale = pageContext.locale || "en-US"
  const seoData = {
    title: "404",
  }
  if (!isInBrowser) {
    return;
  }
  return (
    <PathContext.Provider
      value={{
        current: path,
        slug: "/404",
        locale: locale,
        activeLanguage: locale.substr(0, 2),
      }}
    >
      <Layout seoData={seoData}>
        <HeroMazeDefault
          subheading="Oops, wrong turn!"
          heading="404"
          content=""
        />

        <section>
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-9 col-xxl-8 main-col">
                <div className="cms-content">
                  <h2>
                    Seems the page you want got lost on the road of life...
                  </h2>
                  <h5>That might be because:</h5>
                  <ul>
                    <li>You typed the web address incorrectly.</li>
                    <li>
                      The page you’re looking for has been moved, updated, or
                      deleted.
                    </li>
                  </ul>
                  <h2>Maybe navigate back to:</h2>
                  <ul>
                    <li>
                      <Link to="/">Homepage</Link>
                    </li>
                    <li>
                      <Link to="/jobs/">Job Listing</Link>
                    </li>
                  </ul>
                  <h5>Good luck, traveler!</h5>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    </PathContext.Provider>
  )
}

export default NotFoundPage
